import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/CategoryPageLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`CoreMedia Campaigns`}</h1>
    <hr></hr>
    <p><strong parentName="p">{`Usable with CoreMedia Content Cloud - Service`}</strong></p>
    <hr></hr>
    <p>{`Previously, our customers relied on creating workflows in CoreMedia to establish their campaigns, utilizing features such
as content and project validity. While these methods have proven useful, we have discovered that, in the long run, this is not ideal.`}</p>
    <p>{`As the end users of our customers become increasingly discerning and demanding, marketing managers face mounting pressure
to consider multiple formats, geographies, channels, languages, and other elements in order to meet their expectations.`}</p>
    <p>{`To address this challenge, it is essential to have the right tool in place for flawless campaign execution. This is precisely
what led us to develop CoreMedia Campaigns: a simpler, more effective way to manage campaigns.`}</p>
    <p>{`With CoreMedia Campaigns, we introduce a cloud-native SaaS application that seamlessly integrates with CoreMedia Content
Cloud through APIs. Campaign content is delivered through a headless architecture, providing a scalable and efficient solution.`}</p>
    <p>{`CoreMedia Campaigns enables rapid campaign management optimized for high-performance campaigns, empowering marketers to
create tailored experiences in a simple and intuitive way.`}</p>
    <p>{`The solution's task-driven UI makes it easy to orchestrate campaigns across all geographies, in all the languages needed, ensuring effective delivery in
today's omnichannel landscape. With CoreMedia Campaigns, you can manage campaigns for all your brands with confidence and efficiency.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      